import React from 'react';
import Layout from '../../../components/layout';
import Seo from '../../../components/seo';
import HeroBgImageListingST from '../../../components/staticSections/HeroBgImageListingST/HeroBgImageListingST';
import BgCardsTextST from '../../../components/staticSections/bgCardsTextST/BgCardsTextST';
import OutcomeST from '../../../components/staticSections/OutcomeST/OutcomeST';
import FaqST from '../../../components/staticSections/FaqST/FaqST';
import Segment from '../../../components/segment';

const IndexPage = () => {
  const lang = 'fr_fr';
  return (
    <Layout lang="fr" headerWitdh="header" showFooter dataTheme="white">
      <Segment id="15aFPY6NamUQUAN3oIEqSx0D2mNElDF7" />
      <Seo title="Joint Academy" language="fr" />
      <HeroBgImageListingST
        lang={lang}
        dataTheme="light"
        calltext="Démarrer aujourd'hui"
        calldataGoal="Download Button Tapped"
        callhref="https://app.adjust.com/qabuft3"
        callclassname="margin-top--md btn btn--primary btn--fullwidth btn--lg margin-bottom--xs btn--lgMob"
      />
      <BgCardsTextST dataTheme="white" lang={lang} />
      <OutcomeST lang={lang} />
      <FaqST lang={lang} />
    </Layout>
  );
};

export default IndexPage;
