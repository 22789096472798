import React from 'react';

import contentData from './data.json';
import Image from '../../image';

const BgCardsTextST = ({ lang, dataTheme, storybook }) => {
  const language = typeof lang === 'undefined' ? 'se_sv' : lang;
  const content = contentData[language];

  return (
    <section data-theme={dataTheme} className="BgCardsTextST">
      <div className="container container--lg margin-top--lg margin-bottom--lg">
        {content
          && content.map((main) => (
            <div key={main}>
              <div className="text--component text--center margin-bottom--md">
                <h2 className="margin-bottom--xs">{main.title}</h2>
                <p>{main.text}</p>
              </div>

              <div className="grid grid--gap-md text--center ">
                {main.cards
                  && main.cards.map((card) => (
                    <div key={card.title} className="col col--12 col--sm-4">
                      <div className="BgCardsTextCard">
                        {storybook ? (
                          <img
                            src="https://via.placeholder.com/400x270"
                            alt="sb pic"
                          />
                        ) : (
                          <Image alt={card.image} filename={card.image} />
                        )}

                        <div className="text--component margin-top--sm margin--sm text--left">
                          <h3 className="text--md">{card.title}</h3>
                          <p>{card.text}</p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </section>
  );
};
export default BgCardsTextST;
